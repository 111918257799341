.my-facebook-button-class{
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}
 
.my-facebook-button-class .MuiSvgIcon-root {
    padding-right: 10px;
}

.my-facebook-button-class:hover {
    cursor: pointer;
    opacity: 0.9;
}